import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit} from '@angular/core';
import {TooltipDirective} from '../../../oex-ui-kit/components/tooltip/tooltip.directive';
import {APIService} from '../../services/api.service';
import {PortalService} from '../../portal/services/portal-service';
import {ISavedSearchItem, ISavedSearchResponse} from '../../services/api.types';
import {ActivatedRoute, ActivatedRouteSnapshot, ResolveFn, RouterLink, RouterStateSnapshot} from '@angular/router';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ModalData, ModalService} from '../../services/modal.service';
import {DialogService} from '../../services/dialogService';
import {ROUTE_MARKETPLACE} from '../../services/global-types';

export const resolveSavedSearches: ResolveFn<ISavedSearchResponse> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(APIService)
    .getSavedSearches()
    .then(data => {
      data.items.forEach(s => {
        const parts = s.searchPath.split('?');
        s.url = '/' + ROUTE_MARKETPLACE + parts[0];
        s.queryParams = JSON.parse('{"' + parts[1].replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) });
        s.queryParams.fromMenu = '1';
      });
      return data;
    })
};

@Component({
    selector: 'oex-hih-saved-searches',
    templateUrl: './saved-searches.component.html',
    styleUrl: './saved-searches.component.scss',
    imports: [
        TooltipDirective,
        RouterLink
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SavedSearchesComponent implements OnInit {
  private api = inject(APIService);
  private route = inject(ActivatedRoute);
  private cdr = inject(ChangeDetectorRef);
  private ds = inject(DialogService);
  private modal = inject(ModalService);
  private ps = inject(PortalService);

  items: ISavedSearchItem[] = [];
  data$ = this.route.data.pipe(takeUntilDestroyed());

  ngOnInit() {
    this.data$.subscribe(d => {
      this.items = d.data.items;
      this.cdr.detectChanges();
    });
  }

  async toggleSubscription(item: ISavedSearchItem, e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.ps.setProgress(true);
    try {
      item.subscription = !item.subscription;
      await this.api.saveSearchItem(item);
      this.cdr.detectChanges();
    } finally {
      this.ps.setProgress(false);
    }
  }

  onDeleteClick(item: ISavedSearchItem, e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    const m = this.modal.show(<ModalData>{
      title: 'Confirmation',
      cancel: true,
      message: `Are you sure you want to delete "${item.name}" search?`,
      buttons: [
        {text: 'No', cancel: true},
        {
          text: 'Yes', default: true, cancel: true, click: _ => {
            this.deleteSearch(item);
          }
        }
      ]
    });
  }

  private async deleteSearch(item: ISavedSearchItem) {
    this.ps.setProgress(true);
    try {
      await this.api.deleteSavedSearch(item.id);
      const idx = this.items.indexOf(item);
      if (idx !== -1) {
        this.items.splice(idx, 1);
        this.cdr.detectChanges();
      }
    } catch (e) {

    } finally {
      this.ps.setProgress(false);
    }
  }
}
